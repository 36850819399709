.c-header-search__results::-webkit-scrollbar {
  @apply h-2 w-2 rounded bg-transparent;
}

/* The draggable part of the scrollbar */
.c-header-search__results::-webkit-scrollbar-thumb {
  @apply rounded border-2 border-transparent bg-brabners-navy-100 bg-opacity-80 bg-clip-content;
}

/* The part of the scrollbar track that is not occupied by the thumb */
.c-header-search__results::-webkit-scrollbar-track {
  @apply bg-transparent;
}

.c-header-search__results::-webkit-scrollbar-corner {
  @apply bg-transparent;
}
